<template src="./jobs.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import {formatActive} from "../../../shared/utils";

export default {
  name: "Jobs",
  components: {'tinymceEditor': Editor},
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      fields: [
        { key: 'name', label: 'Berufsbezeichnung'},
        { key: 'date', label: 'Online bis', formatter: value => { return this.$options.filters.formatDate(value) }},
        { key: 'requests_count', label: 'Bewerbungen'},
        { key: 'active', label: 'Status', formatter: formatActive},
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      meta: {},
    }
  },
  methods: {
    async loadItems(ctx) {
      let self = this;
      const params = '?page=' + ctx.currentPage;

      return Http.request('GET', '/jobs' + params)
        .then(function (response) {
          self.meta = response.meta;
          return response.data;
        });
    },
    toggleActive (id, state) {
      let self = this;
      Http.request('POST', '/jobs/' + id + '/active/' + (+!state))
        .then(function (response) {
          if (self.$route.fullPath === '/website/jobs') {
            self.$root.$emit('bv::refresh::table', 'jobsTable')
          }
          if (typeof self.$route.params.id !== 'undefined') {
            self.job.active = response.data.active;
          }
        });
    },
    deleteJob (id) {
      let self = this;
      self.$bvModal.msgBoxConfirm("Diesen Job wirklich löschen?", {
          headerBgVariant: 'primary',
          title: 'Job löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer) {
            Http.request('DELETE', '/jobs/' + id)
              .then(function () {
                if (self.$route.fullPath === '/website/jobs') {
                  self.$root.$emit('bv::refresh::table', 'jobsTable')
                }
                if (typeof self.$route.params.id !== 'undefined') {
                  return self.$router.push('/website/jobs');
                }
              });
          }
        });
    },
  }
}
</script>

<style scoped></style>
